
import { defineComponent } from "vue";
import GuestLayout from "@/layout/GuestLayout.vue";
import GuestTheoryBlock from "@/views/Guest/GuestTheoryBlock.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "GuestHome",
  components: { GuestTheoryBlock, GuestLayout },
  setup() {
    const router = useRouter();
    const onStart = () => {
      router.push({ name: "GuestTheory" });
    };
    return {
      onStart,
    };
  },
});
